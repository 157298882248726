import './ScrollDownButton.css';

const ScrollDownButton = () => {
    return (
        <section id="section05" class="demo">
            <a href="#about"><span></span>Scroll Down</a>
        </section>
    )
}

export default ScrollDownButton;